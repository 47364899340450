import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { DesignRow, Heading1, IntenseText } from 'components/design'

const ProjectsTopSection = ({ title, subtitle, bold_paragraph }) => (
	<DesignRow
		className="mt-72"
		leftColumn={<Heading1>{title}</Heading1>}
		rightColumn={
			<IntenseText className="lg:mt-5">
				<PrismicRichText field={bold_paragraph.richText} />
			</IntenseText>
		}
	/>
)

export default ProjectsTopSection
