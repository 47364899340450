import React from 'react'
// import { Helmet } from 'react-helmet'
import { Root } from 'templates/layout'
import ProjectAttributes from 'components/project-attributes'
import TopSection from './top'
import InnerNav from './inner-nav'
import GalleryWithSlideshow from 'templates/gallery-with-slideshow'

const GalleryPageTpl = (props) => {
	const {
		lists: { full, components, imagesOnly },
		projectRepository,
		tag,
		topContent,
	} = props.pageContext || props

	const buildDescriptorByIndex = (index) => (
		<ProjectAttributes
			project={projectRepository[full[index].project.document.id]}
			hideLink
		/>
	)

	return (
		<Root>
			<TopSection {...topContent} />
			<InnerNav tag={tag} />
			<GalleryWithSlideshow
				componentList={components}
				imageList={imagesOnly}
				buildDescriptorByIndex={buildDescriptorByIndex}
			/>

			{/* TODO: generate responsive links for preload
			<Helmet>
				{list.slice(0, 4).map((item) => (
					<link
						rel="preload"
						as="image"
						href={item.gallery_image.url}
						key={item.gallery_image.url}
					/>
				))}
			</Helmet>*/}
		</Root>
	)
}

export default GalleryPageTpl
