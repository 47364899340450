import React from 'react'
import { Link } from 'gatsby'
import { Heading5 } from 'components/design'

const innerLinks = [
	{ href: '/projects/', text: 'All' },
	{ href: '/projects/tag/exterior/', text: 'Exterior' },
	{ href: '/projects/tag/interior/', text: 'Interior' },
	{ href: '/projects/tag/animation/', text: 'Animation' },
]

const InnerNavSection = ({ tag }) => (
	<section className="inner-nav mx-6 mt-6 flex items-center justify-between overflow-x-auto md:mt-20 lg:mx-auto lg:mt-40 lg:w-3/5">
		<Heading5 className="py-4">Filter:</Heading5>

		{innerLinks.map((link) =>
			link.text !== tag ? (
				<Link
					to={link.href}
					key={link.href}
					className="inner-link ml-8 py-4 lg:ml-0"
				>
					{link.text}
				</Link>
			) : (
				<span className="inner-link-current ml-8 py-4 lg:ml-0" key={link.href}>
					{link.text}
				</span>
			)
		)}

		{innerLinks.map(({ text }) => text).indexOf(tag) === -1 ? (
			<span className="inner-link-current ml-8 py-4 lg:ml-0">{tag}</span>
		) : (
			''
		)}
	</section>
)

export default InnerNavSection
